// storage keys
const storageKeys = {
  parentName: 'forecast-calculation',
  settings: 'forecast-settings',
  scheduled: 'forecast-scheduled',
  manual: 'forecast-manual',
};

// orders
const COMPONENT_NAMES = {
  settings: 'ForecastCalculationSettings',
  scheduled: 'ForecastCalculationScheduled',
  manual: 'ForecastCalculationManual',
};
const settingsTab = { id: 'forecast-calculation-settings', title: 'Настройка параметров', componentName: COMPONENT_NAMES.settings };
const scheduledLaunchTab = { id: 'forecast-calculation-scheduled', title: 'Запуск по расписанию', componentName: COMPONENT_NAMES.scheduled };
const manualLaunchTab = { id: 'forecast-calculation-manual', title: 'Ручной запуск', componentName: COMPONENT_NAMES.manual };

// order card

module.exports = {
  COMPONENT_NAMES,
  storageKeys,
  settingsTab,
  scheduledLaunchTab,
  manualLaunchTab,
};
