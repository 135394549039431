var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "forecast-calculation",
      attrs: { id: "forecast-calculation" }
    },
    [
      _c(
        "header",
        { staticClass: "forecast-calculation__header" },
        [
          _c("ao-tabs", {
            attrs: {
              staticTabs: _vm.tabs,
              currentTab: _vm.reactiveData.currentTabView
            },
            on: { onSelect: _vm.selectTab }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "forecast-calculation__main" },
        [_c(_vm.currentComponent, { tag: "component" })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }