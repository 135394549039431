<template>
  <div id="forecast-calculation" class="forecast-calculation">
    <header class="forecast-calculation__header">
      <ao-tabs
        :staticTabs="tabs"
        :currentTab="reactiveData.currentTabView"
        @onSelect="selectTab"
      />
    </header>
    <main class="forecast-calculation__main">
      <component :is="currentComponent" />
    </main>
  </div>
</template>

<script>
const {
  storageKeys,
  settingsTab,
  scheduledLaunchTab,
  manualLaunchTab,
} = require('../../constants/forecast-calculation');
// async components
/* eslint-disable import/no-dynamic-require, global-require */
const AoTabs = (resolve) => {
  require(['../../components/ao-tabs.vue'], resolve);
};
const ForecastCalculationSettings = (resolve) => {
  require(['./forecast-calculation-settings.vue'], resolve);
};
const ForecastCalculationScheduled = (resolve) => {
  require(['./forecast-calculation-scheduled.vue'], resolve);
};
const ForecastCalculationManual = (resolve) => {
  require(['./forecast-calculation-manual.vue'], resolve);
};

const localeComponents = {
  AoTabs,
  ForecastCalculationSettings,
  ForecastCalculationScheduled,
  ForecastCalculationManual,
};

module.exports = {
  components: { ...localeComponents },

  provide() {
    return {
      reactiveProvidedforecastCalculationData: this.reactiveData,

      forecastCalculationProvidable: {
        entityStorageKey: storageKeys.forecastCalculationEntity,
      },
    };
  },

  data() {
    return {
      tabs: [settingsTab, scheduledLaunchTab, manualLaunchTab],
      reactiveData: {
        currentTabView: null,
      },
      tabForClose: null,
      closeTabModalVisible: false,
    };
  },

  computed: {
    currentTabComponentName() {
      return this.reactiveData.currentTabView?.componentName || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id ?? null;
    },
    currentComponent() {
      return localeComponents[this.currentTabComponentName] || null;
    },
  },

  mounted() {
  },

  methods: {
    // tabs select, add, remove
    selectTab(selectedTab) {
      this.reactiveData.currentTabView = selectedTab;
    },
  },
};
</script>

  <style  scoped>
  .forecast-calculation {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: 100%;
    overflow: auto;
    padding: 12px;
  }

  .forecast-calculation__main {
    flex: 1 0 auto;
  }

  .category-remove-modal>>>.custom-modal {
    max-width: 420px;
  }
  </style>
